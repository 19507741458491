//@author: devin

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { jSith } from "../../util/jquery-replacement";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25DropdownPaginatedComponent } from "./s25.dropdown.paginated.component";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-dropdown-item")
@Component({
    selector: "s25-dropdown-item",
    template: `
        @if (this.init) {
            <div>
                <!-- regular item -->
                @if (this.init && !item.isGroup && !item.items) {
                    <div
                        class="ngDropdownItem {{ item.isSelected ? ' ngDropdownHighlighted' : '' }} {{
                            item.lastStaticItem ? 'ngBottomBlackBorder' : ''
                        }} ngParentSelect"
                        [attr.id]="'ngDropdownId-' + item.dropDownUUID"
                        (click)="parent.select(item, $event)"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                this.choice;
                                context: {
                                    item: this.item,
                                    $transcludeCtrl: this.parent,
                                    $transcludeSource: this.parent.source,
                                }
                            "
                        ></ng-container>
                    </div>
                }
                <!-- list of nested items (item.items) -->
                @if (init && !item.isGroup && item.items) {
                    <div class="ngDropdownItem {{ item.isSelected ? ' ngDropdownHighlighted' : '' }}">
                        <div
                            [attr.id]="'ngDropdownArrayId-' + item.dropDownUUID"
                            (click)="toggleCollapse($event)"
                            class="c-dropdownPaginated-parent {{
                                !item.isCollapsed ? 'c-dropdownPaginated-parent--isExpanded' : ''
                            }}"
                            tabindex="0"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    arrayChoice;
                                    context: {
                                        item: this.item,
                                        $transcludeCtrl: this,
                                        $transcludeSource: this.parent.source,
                                    }
                                "
                            ></ng-container>
                        </div>
                        <ul class="c-dropdownPaginated{{ !item.isCollapsed ? '--isExpanded' : '' }}" tabindex="0">
                            @for (nestedItem of item.items; track trackByFn($index, nestedItem)) {
                                <li
                                    class="{{ !nestedItem.items ? 'ui-select-choices-row' : '' }}"
                                    (click)="parent.select(nestedItem, $event)"
                                >
                                    <s25-dropdown-item
                                        [item]="nestedItem"
                                        [parent]="parent"
                                        [choice]="choice"
                                        [arrayChoice]="arrayChoice"
                                    ></s25-dropdown-item>
                                </li>
                            }
                        </ul>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DropdownItemComponent implements OnInit {
    @Input() item: DropDownItem;
    @Input() parent: S25DropdownPaginatedComponent;
    @Input() choice: TemplateRef<any>;
    @Input() arrayChoice?: TemplateRef<any>;

    init: Boolean;

    constructor(private elementRef: ElementRef) {}

    trackByFn(idx: any, item: any) {
        return idx;
    }

    forEachItem(parentItem: any, callback: Function) {
        if (parentItem && parentItem.nestedItems) {
            jSith.forEach(parentItem.nestedItems, (idx: number, nestedItem: any) => {
                callback(parentItem, nestedItem, idx);
                this.forEachItem(nestedItem, callback);
            });
        }
    }

    toggleCollapse(e: any) {
        e && e.stopPropagation && e.stopPropagation();
        this.item.isCollapsed = !this.item.isCollapsed;
    }

    ngOnInit() {
        if (this.item) {
            this.forEachItem(this.item, (parentItem: any, nestedItem: any, idx: number) => {
                nestedItem.dropDownUUID = parentItem.dropDownUUID + "_" + idx;
            });

            this.item.isCollapsed = true;
            this.init = true;
        }
    }
}
