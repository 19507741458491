//@author: devin

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-dropdown-test")
@Component({
    selector: "s25-dropdown-test",
    template: `
        <!--        <s25-dropdown-paginated [choice]="choice" [model]="this.model" [onSelect]="this.onSelect"></s25-dropdown-paginated>-->
        <!--        <ng-template #choice let-item="item">-->
        <!--            <div [innerHTML]="item.itemName"></div>-->
        <!--        </ng-template>-->
        <s25-bpe-vars-dropdown [(chosen)]="this.chosen" [onSelect]="this.onSelect"></s25-bpe-vars-dropdown>
        <!--            <s25-event-type-dropdown [chosen]="this.chosen" [onSelect]="this.onSelect"></s25-event-type-dropdown>-->
        <!--           <s25-location-dropdown [chosen]="this.chosen" [onSelect]="this.onSelect"></s25-location-dropdown>-->
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DropdownTestComponent {
    chosen: DropDownItem = null;

    model: any = {
        searchEnabled: true,
        scrollToSelected: true,
        items: [
            { itemId: 123, itemName: "Hi" },
            { itemId: 456, itemName: "Bye" },
            { itemId: 789, itemName: "HiBye" },
            { itemId: 1, itemName: "Foo" },
            { itemId: 2, itemName: "FooBar" },
            { itemId: 3, itemName: "Byessss" },
            { itemId: 4, itemName: "Say Good Bye and Hello or Hi" },
            { itemId: 5, itemName: "Food" },
            { itemId: 6, itemName: "Good Food" },
            { itemId: 7, itemName: "High Bar" },
        ],
    };

    onSelect() {
        console.log(this.chosen);
    }

    constructor() {}
}
